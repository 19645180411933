import { gql } from '@/shared/utils';

export default gql`
  query GetSoftEmbargoedCountries {
    softEmbargoedCountries {
      code
      label
    }
  }
`;
