var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('FormFieldGroup',{attrs:{"data-testid":"country-dropdown","is-field-required":"","field-rules":{
    required: true,
    maxCharCountry: 60,
    notEmbargoed: true,
    max: 64,
    availableOnAdditionalCountryList: _vm.softEmbargoedCountries,
  },"field-description":_vm.fieldDescription,"field-name":_vm.fieldName,"field-label":_vm.label,"inner-vid":_vm.fieldName},scopedSlots:_vm._u([{key:"field",fn:function({ hasError, fieldName }){return [_c('FormSelectField',{staticClass:"country-select",attrs:{"selected-value":_vm.countryTemplateModel,"name":fieldName,"is-required":"","has-error":hasError,"options":_vm.countriesToDisplay,"allow-custom-value":""},model:{value:(_vm.countryTemplateModel),callback:function ($$v) {_vm.countryTemplateModel=$$v},expression:"countryTemplateModel"}})]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }