
import { defineComponent } from '@nuxtjs/composition-api';
import { ValidationProvider } from 'vee-validate';
import Icon from '@/components/icon/Icon.vue';
import InfoMessageCard from '@/components/cards/info-message-card/InfoMessageCard.vue';

export default defineComponent({
  components: {
    ValidationProvider,
    InfoMessageCard,
    Icon,
  },
  props: {
    fieldRules: {
      type: [String, Object],
      default: null,
    },
    fieldTag: {
      type: String,
      default: 'div',
    },
    fieldDescription: {
      type: String,
      default: null,
    },
    fieldName: {
      type: String,
      required: true,
    },
    fieldLabel: {
      type: String,
      default: null,
    },
    fieldCustomError: {
      type: String,
      default: null,
    },
    isFieldRequired: {
      type: Boolean,
      default: false,
    },
    isLabelSsrOnly: {
      type: Boolean,
      default: false,
    },
    isLabelHidden: {
      type: Boolean,
      default: false,
    },
    showErrorAsInfoMessage: {
      type: Boolean,
      default: false,
    },
    infoMessageHeadingTag: {
      type: String,
      default: 'h4',
    },
    customErrorMessageFormatter: {
      type: Function,
      default(args: string) {
        return args;
      },
    },
    innerVid: {
      type: String,
      required: false,
      default: '',
    },
    isFieldIndicatorHidden: {
      type: Boolean,
      default: false,
    },
    labelClass: {
      type: String,
      default: '',
    },
  },
});
