import { gql } from '@/shared/utils';

export default gql`
  query GetBillingCountires {
    billingAddressCountries {
      id
      name
      code
      isEmbargoed
    }
  }
`;
