
import { defineComponent } from '@nuxtjs/composition-api';
import { ValidationObserver } from 'vee-validate';
import { FormWrapperSubmission } from './form.types';

export default defineComponent({
  components: {
    ValidationObserver,
  },
  setup: (_, ctx) => {
    const formSubmissionHandler = (args: FormWrapperSubmission): void => {
      const { event, resetForm, handleSubmit, valid, invalid } = args;

      event.preventDefault();

      handleSubmit(() => {
        ctx.emit('submit:action', event);
      });

      if (valid) {
        resetForm();
      }

      if (invalid) {
        ctx.emit('submit:invalid');
      }
    };

    return { formSubmissionHandler };
  },
});
