
import { defineComponent } from '@nuxtjs/composition-api';
import Icon from '@/components/icon/Icon.vue';

export default defineComponent({
  components: {
    Icon,
  },
  props: {
    heading: {
      type: String,
      required: true,
    },
    headingTag: {
      type: String,
      default: 'h4',
    },
    description: {
      type: String,
      default: null,
    },
  },
});
