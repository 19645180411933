import { gql } from '@/shared/utils';

export default gql`
  query GetBillingRegions($countryId: Int!) {
    billingAddressCountryRegions(countryId: $countryId) {
      id
      name
    }
  }
`;
