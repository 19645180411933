import { useContext, ref, useFetch } from '@nuxtjs/composition-api';
import {
  BillingCountry,
  BillingRegion,
  BillingCountriesData,
  BillingRegionsData,
} from '@/shared/types';
import {
  GetBillingCountiresQuery,
  GetBillingCountiresQueryVariables,
  GetBillingRegionsQuery,
  GetBillingRegionsQueryVariables,
  QueryBillingAddressCountryRegionsArgs,
} from '@/shared/graphql/schema.types';
import getBillingCountiresQuery from '@/shared/graphql/billing/getBillingCountries.gql';
import getBillingRegionsQuery from '@/shared/graphql/billing/getBillingRegions.gql';

export const useGetBillingCountries = (): BillingCountriesData => {
  const ctx = useContext();
  const billingCountries = ref<BillingCountry[] | []>([]);
  const isBillingCountriesPending = ref<boolean>(true);
  const isBillingCountriesError = ref<boolean>(false);

  const { fetch } = useFetch(async () => {
    try {
      const response = await ctx.$gqlFetch<
        GetBillingCountiresQuery,
        GetBillingCountiresQueryVariables
      >(getBillingCountiresQuery);

      const countries = response.data.billingAddressCountries.map(
        ({ name, ...rest }) => ({
          label: name,
          ...rest,
        })
      );

      billingCountries.value = countries;
      isBillingCountriesPending.value = false;
    } catch (error) {
      isBillingCountriesPending.value = false;
      isBillingCountriesError.value = true;
    }
  });

  fetch();

  return {
    billingCountries,
    isBillingCountriesPending,
    isBillingCountriesError,
  };
};

export const useGetBillingRegions = (): BillingRegionsData => {
  const ctx = useContext();
  const billingRegions = ref<BillingRegion[] | []>([]);
  const isBillingRegionsPending = ref<boolean>(false);
  const isBillingRegionsError = ref<boolean>(false);

  const resetBillingRegions = (): void => {
    billingRegions.value = [];
  };

  const fetchBillingRegions = async (
    variables: QueryBillingAddressCountryRegionsArgs
  ): Promise<void> => {
    isBillingRegionsPending.value = true;

    try {
      const response = await ctx.$gqlFetch<
        GetBillingRegionsQuery,
        GetBillingRegionsQueryVariables
      >(getBillingRegionsQuery, variables);

      const regions = response.data.billingAddressCountryRegions.map(
        ({ name, ...rest }) => ({
          label: name,
          ...rest,
        })
      );

      billingRegions.value = regions;
      isBillingRegionsPending.value = false;
    } catch (error) {
      isBillingRegionsPending.value = false;
      isBillingRegionsError.value = true;
    }
  };

  return {
    billingRegions,
    isBillingRegionsPending,
    isBillingRegionsError,
    fetchBillingRegions,
    resetBillingRegions,
  };
};
