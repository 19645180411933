export type CaptchaV3Data = {
  validateCaptchaV3: (
    action: CaptchaV3Actions,
    captchaToken: string
  ) => Promise<void>;
  getTokenCaptchaV3: (action: CaptchaV3Actions) => Promise<string>;
};

export enum CaptchaV3Actions {
  BROCHURE_SIGN_UP = 'brochureSignUp',
  MARKETING_SIGN_UP = 'marketingSignUp',
  B2B_ENQUIRY = 'b2bEnquiry',
  ACTIVATION_FORM = 'activationForm',
  LOGIN = 'login',
  PERSONAL_DETAILS = 'bookingPersonalDetails',
}
