import { getCurrentInstance } from 'vue';
import { API } from '@/config/api.config';
import { CaptchaV3Data } from '@/composables/captchaV3/useCatptchaV3.types';
import { FeatureFlagName } from '@/shared/types/feature-flags/feature-flags';

export const useCaptchaV3 = (): CaptchaV3Data => {
  const instance = getCurrentInstance();
  const { $axios, $config, $sentry } = instance!.proxy;
  const captchaKey = $config.CAPTCHA_KEY_V3 || '';
  const invalidCaptchaMessage = 'Unable to validate captcha. Token: ';
  const { $isFeatureEnabled } = instance!.proxy;
  const isCaptchaForAutomationTestingEnabled = $isFeatureEnabled(
    FeatureFlagName.USE_CAPTCHA_FOR_AUTOMATION_TESTING
  );

  // eslint-disable-next-line require-await
  const getTokenCaptchaV3 = async (action: string): Promise<string> => {
    if (isCaptchaForAutomationTestingEnabled) {
      return '';
    }

    return new Promise((resolve, reject) => {
      try {
        // @ts-ignore
        grecaptcha.ready(() => {
          // @ts-ignore
          grecaptcha.execute(captchaKey, { action }).then((token: string) => {
            resolve(token);
          });
        });
      } catch (err) {
        reject(err);
      }
    });
  };

  const validateCaptchaV3 = async (
    action: string,
    captchaToken?: string
  ): Promise<void> => {
    if (isCaptchaForAutomationTestingEnabled) {
      return;
    }

    const token = captchaToken || (await getTokenCaptchaV3(action));
    try {
      await $axios.post(API.captcha.validateURL, { token });
    } catch (_) {
      $sentry.captureException(`${invalidCaptchaMessage} ${token}`);
      throw new Error(`${invalidCaptchaMessage} ${token}`);
    }
  };

  return { validateCaptchaV3, getTokenCaptchaV3 };
};
