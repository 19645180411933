import { useContext, ref, useFetch } from '@nuxtjs/composition-api';
import {
  SoftEmbargoedCountries,
  SoftEmbargoedCountriesData,
} from '@/shared/types/billing/billing.types';
import {
  GetSoftEmbargoedCountriesQuery,
  GetSoftEmbargoedCountriesQueryVariables,
} from '@/shared/graphql/schema.types';
import getSoftEmbargoedCountries from '@/shared/graphql/billing/getSoftEmbargoedCountries.gql';

export const useGetSoftEmbargoedCountries = (): SoftEmbargoedCountriesData => {
  const ctx = useContext();
  const softEmbargoedCountries = ref<SoftEmbargoedCountries[] | []>([]);
  const isSoftEmbargoedCountriesPending = ref<boolean>(false);
  const isSoftEmbargoedCountriesError = ref<boolean>(false);

  const { fetch } = useFetch(async () => {
    isSoftEmbargoedCountriesPending.value = true;

    try {
      const response = await ctx.$gqlFetch<
        GetSoftEmbargoedCountriesQuery,
        GetSoftEmbargoedCountriesQueryVariables
      >(getSoftEmbargoedCountries);

      softEmbargoedCountries.value = response.data.softEmbargoedCountries;
    } catch (error: any) {
      isSoftEmbargoedCountriesPending.value = false;
      isSoftEmbargoedCountriesError.value = true;
    }
  });

  fetch();

  return {
    softEmbargoedCountries,
    isSoftEmbargoedCountriesPending,
    isSoftEmbargoedCountriesError,
  };
};
