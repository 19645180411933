
import { defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  props: {
    hasError: {
      type: Boolean,
      default: false,
    },
    hasExtraInfo: {
      type: Boolean,
      default: false,
    },
    value: {
      type: String,
      default: '',
    },
    fieldName: {
      type: String,
      required: true,
    },
    placeholderText: {
      type: String,
      default: '',
    },
  },
  setup: (_, ctx) => {
    const handleInputEvent = (event: InputEvent) => {
      const value = (event.currentTarget as HTMLInputElement).value;
      ctx.emit('input', value.trim(), event);
    };

    return {
      handleInputEvent,
    };
  },
});
