
import {
  computed,
  ComputedRef,
  defineComponent,
  PropType,
  ref,
  watch,
} from '@nuxtjs/composition-api';
import FormFieldGroup from '@/components/form/FormFieldGroup.vue';
import FormSelectField from '@/components/form/FormSelectField.vue';
import { useGetSoftEmbargoedCountries } from '@/composables/booking/useGetSoftEmbargoedCountries';
import { BillingCountry } from '~/shared/types';

type CountryOption = {
  label: string;
  code: string;
};

type CountryInput = {
  label?: string;
  code?: string;
};

type HandleCountryChange = (newCountrySelected: CountryOption) => void;

const findCountryByCountryInputPredicate =
  (newSelecion: { label?: string; code?: string }) =>
  (country: { label: string; code: string } | BillingCountry) => {
    return (
      country.label.toLowerCase().trim() ===
        newSelecion?.label?.toLowerCase().trim() ||
      country.code === newSelecion?.code
    );
  };

export default defineComponent({
  components: {
    FormFieldGroup,
    FormSelectField,
  },
  props: {
    label: {
      type: String,
      default: 'Country',
    },
    fieldName: {
      type: String,
      default: 'country',
    },
    fieldDescription: {
      type: String,
      default: 'country',
    },
    handleCountryChange: {
      type: Function as PropType<HandleCountryChange>,
      default: () => {},
    },
    selectedValue: {
      type: Object as PropType<CountryInput>,
      default: null,
    },
    displayEmbargoedCountries: {
      type: Boolean,
      default: true,
    },
    billingCountries: {
      type: Array as PropType<BillingCountry[] | [] | undefined>,
      required: true,
    },
  },
  setup: (props) => {
    const countryTemplateModel = ref<CountryInput>();
    const { softEmbargoedCountries } = useGetSoftEmbargoedCountries();

    const countriesToDisplay = computed(() => {
      if (!props.billingCountries) {
        return [];
      }

      return props.displayEmbargoedCountries
        ? props.billingCountries
        : props.billingCountries.filter((country) => !country.isEmbargoed);
    });
    const selectedValue = computed(() => props.selectedValue);

    const prefillCountry = (
      input: CountryInput | undefined,
      countries: BillingCountry[] | [] | undefined = []
    ) => {
      if (!input || (!input.code && !input.label)) {
        countryTemplateModel.value = undefined;
        return;
      }
      const predicate = findCountryByCountryInputPredicate(input);
      countryTemplateModel.value =
        countries.find(predicate) ||
        softEmbargoedCountries.value.find(predicate);
    };

    prefillCountry(selectedValue.value, props.billingCountries);

    watch<[ComputedRef<CountryInput>, ComputedRef<BillingCountry[]>]>(
      [selectedValue, countriesToDisplay],
      ([value, countries]) => prefillCountry(value, countries)
    );

    watch(countryTemplateModel, (newCountrySelection) => {
      if (!newCountrySelection) {
        return;
      }
      const predicate = findCountryByCountryInputPredicate(newCountrySelection);
      const newSelectedCountry =
        countriesToDisplay.value.find(predicate) ||
        softEmbargoedCountries.value.find(predicate);

      if (newSelectedCountry) {
        newCountrySelection!.code = newSelectedCountry!.code;
        newCountrySelection!.label = newSelectedCountry!.label;
        props.handleCountryChange(newSelectedCountry);
        countryTemplateModel.value = newSelectedCountry;
      }
    });
    return { countriesToDisplay, countryTemplateModel, softEmbargoedCountries };
  },
});
