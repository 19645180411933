var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('ValidationObserver',{attrs:{"slim":""},scopedSlots:_vm._u([{key:"default",fn:function({ handleSubmit, reset, valid, failed, invalid }){return [_c('form',{on:{"submit":(event) =>
        _vm.formSubmissionHandler({
          event,
          handleSubmit,
          resetForm: reset,
          valid,
          invalid,
        })}},[_vm._t("form-content",null,{"isFormInvalid":failed})],2)]}}],null,true)})
}
var staticRenderFns = []

export { render, staticRenderFns }